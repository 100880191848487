import React from "react";
import Icon from "@mdi/react";
import {
  mdiSortBoolDescending,
  mdiSortBoolAscending,
  mdiCheckboxBlankCircleOutline,
  mdiArrowSplitVertical,
} from "@mdi/js";
import i18n from "i18next";
// import { useComponentsStore } from "../../Stores/componentsStore";

export function TableHeadings({ column }) {
  // const [isDark] = useComponentsStore((state) => [state.isDark]);

  return column.Header !== "" ? (
    <React.Fragment>
      {column.Header !== "Ai" ? (
        <div>
          {column.isSorted ? (
            column.isSortedDesc ? (
              <Icon
                path={mdiSortBoolDescending}
                className="dropdown tc-table-sorting-icons-position"
                title={i18n.t("Sorted Descending")}
                size={0.7}
              />
            ) : (
              <Icon
                path={mdiSortBoolAscending}
                className="dropup tc-table-sorting-icons-position"
                title={i18n.t("Sorted Ascending")}
                size={0.7}
              />
            )
          ) : (
            <div className="tc-text-muted">
              <Icon
                path={mdiCheckboxBlankCircleOutline}
                className="dropup tc-table-sorting-icons-position"
                title={i18n.t("No sorting applied")}
                size={0.7}
              />
            </div>
          )}
        </div>
      ) : null}
      <div className={`me-auto ${column.Header !== "Ai" ? "text-truncate" : "tc-whitespace-nowrap"} tc-width-75p`}>
        {column.render(() => i18n.t(column.Header))}
      </div>

      {column.Header !== "Ai" ? (
        <div
          {...column.getResizerProps()}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={`resizer`}
        >
          <Icon path={mdiArrowSplitVertical} size={1} />
        </div>
      ) : null}
    </React.Fragment>
  ) : null;
}
